$(window).on('load', function () {

    // Selections
    window.bp = {
        sl: 480,
        md: 768,
        bg: 970,
        ld: 1024,
        wd: 1200,
        max: 1312
    }
    window.carousels = $('.owl-carousel');
    window.specialCarousels = $('.owl-special');
    window.counters = $('.counters');
    window.linkCopiers = $('.sharer__link a');
    window.goals = $('.goals');
    window.hiders = $('.hider');
    window.infiniteScrolls = $('.infinite-scroll');
    window.languageToggler = $('.language__control[role="on"]');
    window.mainNav = $('.menu-wrp');
    window.mainNavAnchor = $('.menu-wrp > ul > li > a');
    window.maxFitters = $('[fitmax]');
    window.notfound = $('body.notfound');
    window.portfolio = $('.portfolio');
    window.section = $('body').attr('section');
    window.sectionNav = $('.sectionnav');
    window.subNavAnchor = $('.sub-menu-wrp > li > a');
    window.navToggler = $('.navicon-button');
    window.ytPopups = $('.popup-youtube');

    // Functions
    window.capitalize = function (string) {
        var parts = string.split(" ");
        var newparts = [];
        $.each(parts, function (id, p) {
            var first = p[0].toUpperCase();
            var rest = p.substring(1);
            newparts.push(first + rest);
        });
        return newparts.join(" ");
    }
    window.getVPW = function () {
        return $(window).width();
    }
    window.startOWL = function (carousel, options, name) {
        window[name + 'OWL'] = $(carousel).owlCarousel(options);
    }
    window.detectIsIE = function () {
        if (!!window.MSInputMethodContext && !!document.documentMode) {
            $('body').addClass('ie');
        } else if (/Edge/.test(navigator.userAgent)) {
            $('body').addClass('edge');
        }
    }();
    window.setDragScroll = function (action, element, nav) {
        switch (action) {
            case "add":
                element.parent().addClass('dragscroll');
                element.css({
                    position: 'static',
                    top: 'inherit',
                    left: 'inherit',
                    transform: 'none'
                });
                dragscroll.reset();
                if (nav) {
                    $(element).parents('section').append('<button class="dragscroll__prev"></button><button class="dragscroll__next"></button>');
                    var controls = $(element).parents('section').find('button');
                    setControls(controls);
                }
                break;
            case "remove":
                element.parent().removeClass('dragscroll');
                element.removeAttr('style');
                dragscroll.reset();
                if (nav) {
                    $(element).parents('section').find('button').remove();
                }
                break;
        }
    }
    window.enableOwlNav = function (c) {
        $(c).find('.owl-nav').removeClass('disabled');
    }
    window.switchOwl = function (bp, carousel, options, name) {
        var instanceName = name + 'OWL';
        if (getVPW() <= bp) startOWL(carousel, options, name);
        $(window).on('resizeend', function () {
            if (getVPW() <= bp) {
                startOWL(carousel, options, name);
            } else {
                window[instanceName].trigger('destroy.owl.carousel');
            }
        });
    }
    $.fn.isOnScreen = function (tollerance) {
        var win = $(window);
        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height() - tollerance;
        var bounds = this.offset();
        bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();
        return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    // Nav toggler
    navToggler.on('click', function () {
        $(this).toggleClass('open');
        mainNav.toggleClass('active');
    });

    // Main nav
    mainNavAnchor.on('click', function (e) {
        //In visualizzazione mobile c'è il dispiegamento/contrazione del menù, dobbiamo controllare il comportamento
        //del click per poter fare l'animazione di apertura e chiusura
        var ww = $(window).width();
        if (ww < 1023) {
            var $thisSubOuter = $(this).next('.sub-outer');
            if ($thisSubOuter.length != 0) {
                //Ci sono sottoelementi quindi va fatta l'animazione ma non consideriamo il click altrimenti si va
                //alla pagina padre
                $('.sub-outer').filter('.visible').not($thisSubOuter).slideUp(800, function () {
                    $(this).removeClass('visible')
                });
                if ($thisSubOuter.hasClass('visible')) {
                    $thisSubOuter.slideUp(300, function () {
                        $(this).removeClass('visible')
                    });
                } else {
                    $thisSubOuter.slideDown(300, function () {
                        $(this).addClass('visible')
                    });
                }
                //Il trucco è qui
                e.preventDefault();
            }
        }
    });
    if (section) $.each(mainNavAnchor, function (id, a) {
        if (capitalize($(a).text()) === section) $(a).parent().addClass('on');
    });

    // Sub nav
    subNavAnchor.on('click', function (e) {
        var $thirdLevel = $(this).next('ul');
        if ($thirdLevel.length != 0) {
            e.preventDefault();
            if ($thirdLevel.is(':visible')) {
                $thirdLevel.hide();
            } else {
                $thirdLevel.show();
            }
        } else {
            window.location.href = $(this).attr('href');
        }
    });

    // Section navs
    if (sectionNav.length > 0) {
        var page = $('body').attr('page');
        var subpage = $('body').attr('subpage');

        // set items active state
        $.each(sectionNav.find('.sectionnav__item'), function (id, item) {
            var label = $(item).find('.sectionnav__label').text();
            if (label === page || label === subpage) {
                $(item).addClass('on');
            }
        });

        // HorizontalNav activation
        hnavs = new HorizontalNav();
        $(window).on('resizeend', function () {
            hnavs.check();
        });

    }

    // Language toggler
    languageToggler.on('click', function () {
        $(this).parent().toggleClass('language--open');
    });

    // Counters
    if (counters.length > 0) {
        var counterSectionVisible = false;
        $(window).on('scroll', function () {
            if (counterSectionVisible === false && counters.isOnScreen(300)) {
                counterSectionVisible = true;
                $('.js-counter', counters).each(function () {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 1000,
                        easing: 'swing',
                        step: function (now) {
                            if(typeof now === 'number'){
                                if (now % 1 === 0) { // it is a int
                                    $(this).text(Math.ceil(now));
                                } else {
                                    var text = parseFloat(now).toFixed(1);
                                    if (window.location.href.includes("/it/")) {
                                        text = text.replace(".", ",")
                                    }
                                    $(this).text(text);
                                }
                            }

                        }
                    });
                });
            }
        });
    }

    // Nav scaler
    $(window).scroll(function () {
        var body = $('body'),
            scroll = $(window).scrollTop();
        if (scroll >= 100) body.addClass('mini');
        else body.removeClass('mini');
    });

    // Carousels
    if (carousels.length > 0) {
        var pageSubSectionCarousel = function (tomerge) {
            var speed = 1500;
            options = {
                autoplay: false,
                autoplayTimeout: speed * 3,
                dots: false,
                loop: true,
                nav: true,
                navText: ['', ''],
                smartSpeed: speed,
                fluidSpeed: true,
            };

            return $.extend({}, options, tomerge);
        }
        var options = {};
        $.each(carousels, function (id, c) {
            if ($(c).children().length > 1) {
                var name = $(c).attr('owl');
                switch (name) {
                    case "deepenings":
                        var speed = 1700;
                        options[name] = {
                            autoplay: true,
                            autoplayTimeout: speed * 3,
                            dots: false,
                            items: 1,
                            margin: 1,
                            loop: true,
                            nav: true,
                            navText: ['', ''],
                            smartSpeed: speed,
                            fluidSpeed: true,
                            autoplayHoverPause: true,
                        };
                        startOWL(c, options[name], name);
                        $('.owl-next').on('click', function () {
                            var $this = $(this);

                            $this.addClass('timeout-slider');

                            setTimeout(function () {
                                $this.removeClass('timeout-slider');
                            }, speed);
                        });
                        $('.owl-prev').on('click', function () {
                            var $this = $(this);

                            $this.addClass('timeout-slider');

                            setTimeout(function () {
                                $this.removeClass('timeout-slider');
                            }, speed);
                        });
                        break;
                    case "gallery":
                        var dots = $(c).attr('dots') === "1" ? true : false;
                        options[name] = {
                            dots: dots,
                            loop: true,
                            items: 1,
                            margin: 10,
                            nav: true,
                            navText: ['', ''],
                            responsive: {
                                600: {
                                    items: 2,
                                    center: true
                                }
                            }
                        };
                        startOWL(c, options[name], name);
                        break;
                    case "objectives":
                        options[name] = {
                            dots: false,
                            items: 3,
                            loop: true,
                            nav: true,
                            navText: ['', '']
                        };
                        startOWL(c, options[name], name);
                        break;
                    case "portfolio":
                        options[name] = {
                            autoplay: true,
                            dots: false,
                            items: 1,
                            loop: true,
                            nav: true,
                            navText: ['', '']
                        };
                        switchOwl(bp.bg, c, options[name], name);
                        break;
                    case "portafoglio-hp":
                        options[name] = {
                            autoplay: false,
                            dots: false,
                            items: 1,
                            loop: false,
                            nav: true,
                            navText: ['', ''],
                            responsive: {
                                768 : {
                                    items: 2
                                },
                                1280 : {
                                    items: 3
                                }
                            }
                        };

                        startOWL(c, options[name], name);

                        /*if (window.matchMedia("(max-width: 1024px)").matches) {
                            startOWL(c, options[name], name);
                        }*/

                        /*window.addEventListener('resize', () => {
                            if (window.matchMedia("(min-width: 1024px)").matches) {
                                $('.portfolio-preview__divisions').trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                                $('.portfolio-preview__divisions').find('.owl-stage-outer').children().unwrap();
                            } else {
                                $('.portfolio-preview__divisions').addClass('owl-carousel');
                                startOWL(c, options[name], name);
                            }
                        });*/
                        break;
                    case "portafoglio-pagina-interna":
                        let currentSlider = this;
                        let currentSlides = [].slice.call(currentSlider.querySelectorAll('.portfolio-preview__division-pagina-interna'));
                        let responsiveChecker = currentSlides.length > 3 ? true : false;

                        options[name] = {
                            dots: true,
                            loop: true,
                            items: 1,
                            margin: 10,
                            nav: true,
                            navText: ['', ''],
                            responsive: {
                                600: {
                                    items: 3,
                                    dots: responsiveChecker,
                                    loop: responsiveChecker
                                }
                            }
                        };
                        startOWL(c, options[name], name);
                    case "stories":
                        var speed = 3200;
                        options[name] = {
                            autoplay: true,
                            autoplayTimeout: speed * 3,
                            dots: false,
                            items: 1,
                            loop: true,
                            nav: true,
                            navText: ['', ''],
                            smartSpeed: speed,
                            fluidSpeed: true,
                            autoplayHoverPause: true,
                        };
                        startOWL(c, options[name], name);
                        $('.owl-next').on('click', function () {
                            var $this = $(this);

                            $this.addClass('timeout-slider');

                            setTimeout(function () {
                                $this.removeClass('timeout-slider');
                            }, speed);
                        });
                        $('.owl-prev').on('click', function () {
                            var $this = $(this);

                            $this.addClass('timeout-slider');

                            setTimeout(function () {
                                $this.removeClass('timeout-slider');
                            }, speed);
                        });
                        break;
                    case "video-gallery":
                        options[name] = {
                            dots: false,
                            items: 1,
                        };
                        options[name].responsive = {};
                        options[name].responsive[0] = {
                            nav: true,
                            navText: ['', '']
                        }
                        options[name].responsive[bp.bg] = {
                            navContainer: '.video-gallery__alt-owl-nav'
                        }
                        window.videoGalleryRefresh = {};
                        videoGalleryRefresh.alt = getVPW() > bp.bg ? true : false;
                        videoGalleryRefresh.normal = getVPW() > bp.bg ? false : true;

                    function refresh() {
                        var w = getVPW();
                        if (w > bp.bg && !videoGalleryRefresh.alt) {
                            videoGalleryRefresh.alt = true;
                            videoGalleryRefresh.normal = false;
                            videoGalleryOWL.trigger('refresh.owl.carousel');
                        }
                        if (w < bp.bg && !videoGalleryRefresh.normal) {
                            videoGalleryRefresh.alt = false;
                            videoGalleryRefresh.normal = true;
                            videoGalleryOWL.trigger('refresh.owl.carousel');
                        }
                    }

                        startOWL(c, options[name], "videoGallery");
                        break;
                    case "sustainability_highlights":
                    case "sustainability_targets":
                    case "sustainability_acknowledgment":
                        options[name] = {
                            autoplay: false,
                            dots: false,
                            items: 1,
                            loop: true,
                            nav: true,
                            navText: ['', ''],
                            responsive: {
                                1024: {
                                    items: 3,
                                    center: true
                                }
                            }
                        };
                        startOWL(c, options[name], name);
                        break;
                    case "acknowledgment":
                    case "history":
                    // case "society-culture":
                    case "valuable-actions":
                        options[name] = {
                            onInitialized: function () {
                                enableOwlNav(c);
                            },
                            onTranslate: function () {
                                enableOwlNav(c);
                            },
                            onTranslated: function () {
                                enableOwlNav(c);
                            }
                        };
                        options[name].responsive = {};
                        options[name].responsive["0"] = {items: 1};
                        options[name].responsive[bp.md] = {items: 2};
                        options[name].responsive[bp.bg] = {items: 3};
                        startOWL(c, pageSubSectionCarousel(options[name]), name);
                        $(window).on('resizeend', function () {
                            enableOwlNav(c)
                        });
                        break;
                    case "society-culture":
                        options[name] = {
                            loop: false,
                            onInitialized: function () {
                                enableOwlNav(c);
                            },
                            onTranslate: function () {
                                enableOwlNav(c);
                            },
                            onTranslated: function () {
                                enableOwlNav(c);
                            }
                        };
                        options[name].responsive = {};
                        options[name].responsive["0"] = {items: 1};
                        options[name].responsive[bp.md] = {items: 2};
                        options[name].responsive[bp.bg] = {items: 3};
                        startOWL(c, pageSubSectionCarousel(options[name]), name);
                        $(window).on('resizeend', function () {
                            enableOwlNav(c)
                        });
                        break;
                    case "vocations":
                        options[name] = {
                            loop: false,
                            nav: false,
                            onInitialized: function () {
                                enableOwlNav(c);
                            },
                            onTranslate: function () {
                                enableOwlNav(c);
                            },
                            onTranslated: function () {
                                enableOwlNav(c);
                            }
                        };
                        options[name].responsive = {};
                        options[name].responsive["0"] = {items: 1};
                        options[name].responsive[bp.md] = {items: 2};
                        options[name].responsive[bp.bg] = {items: 4};
                        startOWL(c, pageSubSectionCarousel(options[name]), name);
                        $(window).on('resizeend', function () {
                            enableOwlNav(c)
                        });
                        break;
                    case "enhancement-priority":
                        options[name] = {
                            items: 1,
                            loop: false,
                            onInitialized: function () {
                                enableOwlNav(c);
                            },
                            onTranslate: function () {
                                enableOwlNav(c);
                            },
                            onTranslated: function () {
                                enableOwlNav(c);
                            }
                        };
                        startOWL(c, pageSubSectionCarousel(options[name]), name);
                        $(window).on('resizeend', function () {
                            enableOwlNav(c)
                        });
                        break;
                    case "relations_closest":
                    case "relations_others":
                        var switchBP = bp.ld;
                        var bp_sm = 670;
                        var bp_md = bp.bg;
                        options[name] = {
                            autoplay: false,
                            dots: false,
                            loop: false,
                            nav: true,
                            navText: ['', ''],
                            responsive: {}
                        };
                        options[name].responsive["0"] = {};
                        options[name].responsive["0"] = {items: 1};
                        options[name].responsive[bp_sm] = {};
                        options[name].responsive[bp_sm] = {items: 2};
                        options[name].responsive[bp_md] = {};
                        options[name].responsive[bp_md] = {items: 3};
                        switchOwl(switchBP, c, options[name], name);
                        $(window).on('resizeend', function () {
                            if (getVPW() < switchBP) enableOwlNav(c);
                        });
                        break;
                    case "historyYears":
                    case "historyDesc":
                        window.historyActive = function (set) {
                            if (set === undefined) return historySection.attr('active');
                            else historySection.attr('active', set);
                        }
                        window.historyDesc = $('.history__list--desc');
                        window.historyLimit = function (event) {
                            var state = historyState(event);
                            var wrapper = state.carousel;
                            if (state.current === 0) wrapper.addClass('past');
                            else if (state.current === state.total - 1) wrapper.addClass('future');
                            else wrapper.removeClass('past future');
                        }
                        window.historySection = $('.history');
                        window.historyState = function (event) {
                            var state = {};
                            state.carousel = $(event.target);
                            state.old = parseInt(state.carousel.attr('current'));
                            state.current = parseInt(event.item.index);
                            state.total = parseInt(event.item.count);
                            state.dir = state.current > state.old ? "next" : "prev";
                            return state;
                        }
                        window.historyYears = $('.history__list--years');

                    function toggleNav(action, e) {
                        switch (action) {
                            case 'enable':
                                $(e.target).find('.owl-nav>*').removeClass('off');
                                break;
                            case 'disable':
                                $(e.target).find('.owl-nav>*').addClass('off');
                                break;
                        }
                    }

                        if (name === "historyYears") {
                            options[name] = {
                                autoplay: false,
                                center: true,
                                dots: false,
                                loop: false,
                                nav: true,
                                navText: ['', ''],
                                responsive: {},
                                onInitialized: function (e) {
                                    historyLimit(e);
                                    historyActive('years');
                                    $(e.target).attr('current', e.item.index);
                                },
                                onTranslate: function (e) {
                                    toggleNav('disable', e);
                                },
                                onTranslated: function (e) {
                                    var state = historyState(e);
                                    toggleNav('enable', e);
                                    historyLimit(e);
                                    $(state.carousel).attr('current', state.current);
                                    if (historyActive() === "years" && (!state.carousel.hasClass('past') && !state.carousel.hasClass('future'))) {
                                        window.historyDescOWL.trigger(state.dir + '.owl.carousel');
                                    }
                                    if (historyActive() === "years" && (state.carousel.hasClass('past') || state.carousel.hasClass('future')) && (!historyDesc.hasClass('past') && !historyDesc.hasClass('future'))) {
                                        window.historyDescOWL.trigger(state.dir + '.owl.carousel');
                                    } else historyActive('years');
                                }
                            };
                            options[name].responsive["0"] = {};
                            options[name].responsive["0"] = {items: 1};
                            options[name].responsive[bp.md] = {};
                            options[name].responsive[bp.md] = {items: 3};
                            startOWL(c, options[name], name);
                        }
                        if (name === "historyDesc") {
                            options[name] = {
                                autoplay: false,
                                dots: false,
                                items: 1,
                                loop: false,
                                nav: false,
                                responsive: {},
                                onDrag: function (e) {
                                    var state = historyState(e);
                                    historyActive('desc');
                                    if (state.dir === "prev" && historyYears.hasClass('future')) historyYears.addClass('stop');
                                },
                                onInitialized: function (e) {
                                    historyLimit(e);
                                    $(e.target).attr('current', e.item.index);
                                },
                                onTranslated: function (e) {
                                    var state = historyState(e);
                                    historyLimit(e);
                                    state.carousel.attr('current', state.current);
                                    if (historyActive() === "desc" && state.dir === "next" && !historyYears.hasClass('future')) {
                                        window.historyYearsOWL.trigger(state.dir + '.owl.carousel');
                                    }
                                    if (historyActive() === "desc" && state.dir === "prev" && !historyYears.hasClass('past') && !historyYears.hasClass('stop')) {
                                        window.historyYearsOWL.trigger(state.dir + '.owl.carousel');
                                    }
                                    if (historyActive() === "desc" && state.current < state.total - 1 && historyYears.hasClass('stop')) {
                                        historyYears.removeClass('stop')
                                        window.historyYearsOWL.trigger(state.dir + '.owl.carousel');
                                    }
                                }
                            };
                            startOWL(c, options[name], name);
                        }
                        break;
                    case "governance":
                        var w_person = 215;
                        var gut_person = 40;
                        var arrow_sm = 42;
                        var arrow_bg = 60;
                        var switchBP = w_person * 4 + gut_person * 3.5;
                        var bp_2 = w_person * 2 + (gut_person / 2) * 4;
                        var bp_3 = w_person * 3 + (gut_person) * 6;
                        options[name] = {
                            autoplay: false,
                            dots: false,
                            loop: false,
                            nav: true,
                            navText: ['', ''],
                            responsive: {}
                        };
                        options[name].responsive["0"] = {};
                        options[name].responsive["0"] = {items: 1};
                        options[name].responsive[bp_2] = {};
                        options[name].responsive[bp_2] = {items: 2};
                        options[name].responsive[bp_3] = {};
                        options[name].responsive[bp_3] = {items: 3};
                        switchOwl(switchBP, c, options[name], name);
                        $(window).on('resizeend', function () {
                            if (getVPW() < switchBP) enableOwlNav(c);
                        });
                        break;
                }
            } else {
                $(c).removeClass('owl-carousel');
            }
        })
    }

    // Special carousels
    if (specialCarousels.length > 0) {
        var options = {};
        $.each(specialCarousels, function (id, c) {
            var name = $(c).attr('owl');
            switch (name) {
                case "materiality":
                    var switchBP = window.bp.md;
                    options[name] = {
                        autoplay: false,
                        dots: true,
                        items: 1,
                        loop: false,
                        nav: false
                    };
                    $(c).removeClass('owl-special').removeAttr('owl');
                    if (getVPW() < switchBP) {
                        $(c).addClass('materiality--carousel');
                    }
                    c = $(c).find('.table__tbody').addClass('owl-carousel')[0];
                    $(window).on('resizeend', function () {
                        if (getVPW() > switchBP) {
                            $('.materiality').removeClass('materiality--carousel');
                        }
                    });
                    switchOwl(switchBP, c, options[name], name);
                    break;
                case "syntesis":
                    var $altNav = 'owl-nav-alt';
                    var wrapper = $('.syntesis__wrapper');
                    window.syntesisInit = false;
                    var fitThead = function (c) {
                        var heights = [];
                        var rows = [];
                        var ths = $('.table__th');
                        $.each($(c).find('.owl-item.center .table__tr'), function (id, tr) {
                            var h = parseInt($(tr).css('height')) + parseInt($(tr).css('margin-bottom')) + parseInt($(tr).css('border-bottom-width'));
                            $(ths[id]).height(h);
                            heights.push(h);
                            rows.push(tr);
                        });
                        $(ths[0]).height(heights[0] - parseInt($(rows[0]).css('margin-bottom')));
                    }
                    var sizeStageOuter = function (c) {
                        var itemW = $(c).find('.owl-item').width();
                        $(c).find('.owl-stage-outer').width(itemW);
                    }
                    options[name] = {
                        autoplay: false,
                        center: true,
                        dots: false,
                        items: 1,
                        loop: false,
                        nav: true,
                        navContainer: '.' + $altNav,
                        navText: ['', ''],
                        onRefreshed: function () {
                            fitThead(c);
                        },
                        onTranslated: function () {
                            fitThead(c);
                        }
                    };
                    c = $(c).find('.table__tbody-wrapper').addClass('owl-carousel');
                    wrapper.append('<div class="' + $altNav + '"></div>');
                    startOWL(c, options[name], name);
                    sizeStageOuter(c);
                    $(window).on('resizeend', function () {
                        syntesisOWL.trigger('refresh.owl.carousel')
                        sizeStageOuter(c);
                    });
                    break;
            }
        })
    }

    // Portfolio
    if (portfolio.length > 0) new Portfolio();

    if (infiniteScrolls.length > 0) {


        //Traduzione label per il load more
        //---------------------------------------------------------------------------

        var leggiDiPiuLabel = 'Leggi di più';
        var eventiEsauritiLabel = 'Non ci sono più eventi da visualizzare';
        var storieEsauriteLabel = 'Non ci sono più storie da leggere';
        var notizieEsauriteLabel = 'Non ci sono più notizie da visualizzare';
        var articoliEsauriteLabel = 'Non ci sono più articoli da visualizzare';

        //Il locale dovrebbe settato come variabile globale
        try {
            if (typeof (locale) !== 'undefined') {
                if (locale == 'en') {
                    leggiDiPiuLabel = 'Load more';
                    eventiEsauritiLabel = 'No more events';
                    notizieEsauriteLabel = 'No more news';
                    storieEsauriteLabel = 'No more stories';
                    articoliEsauriteLabel = 'No more articles';
                }
            }
        } catch (e) {
        }
        //---------------------------------------------------------------------------


        $.each(infiniteScrolls, function (id, is) {
            var name = $(is).attr('name');
            switch (name) {
                case "events-list":
                    window.news = new InfiniteScroll({
                        anim: {
                            delay: 500,
                            item: "off",
                            loading: "on",
                            stagger: 100,
                            toggler: "off"
                        },
                        ender: {
                            template: '<div class="{{class}} events-list__ender"><p>{{message}}</p></div>',
                            message: eventiEsauritiLabel,
                        },
                        filter: {
                            container: "more-contents__filters",
                            filters: {
                                categories: "more-contents__filters-categories"
                            },
                        },
                        limit: 6,
                        increment: 3,
                        service: '/services/infinite/nextmeetings',
                        showall: "more-contents__all",
                        toggler: {
                            template: '<div class="{{class}} events-list__toggler"><p>{{label}}</p></div>',
                            label: leggiDiPiuLabel
                        }
                    })

                    var eventsLoader = function() {
                        var filters = [].slice.call(document.querySelectorAll('.more-contents__category--coima'));
                        var headerEvents = document.querySelector('.events-list__header');
                        var showAllEvents = document.querySelector('.events-list__header .more-contents__all');

                        var spinner = document.createElement('div');
                        var spinnerFirstEffect = document.createElement('div');
                        var spinnerSecondEffect = document.createElement('div');
                        var spinnerThirdEffect = document.createElement('div');

                        spinner.classList.add('spinner-loading-events');
                        spinnerFirstEffect.classList.add('spinner-first-bounce');
                        spinnerSecondEffect.classList.add('spinner-second-bounce');
                        spinnerThirdEffect.classList.add('spinner-third-bounce');

                        spinner.appendChild(spinnerFirstEffect);
                        spinner.appendChild(spinnerSecondEffect);
                        spinner.appendChild(spinnerThirdEffect);

                        function spinnerAnimation() {
                            headerEvents.appendChild(spinner);

                            setTimeout(function() {
                                headerEvents.removeChild(spinner);
                            }, 1800);
                        }

                        filters.forEach(function(el) {
                            el.addEventListener('click', function() {
                                spinnerAnimation();
                            });
                        });

                        showAllEvents.addEventListener('click', function() {
                            spinnerAnimation();
                        });
                    }
                    eventsLoader();

                    break;
                case "media-video-gallery":
                    window.news = new InfiniteScroll({
                        anim: {
                            delay: 500,
                            item: "off",
                            loading: "on",
                            stagger: 100,
                            toggler: "off"
                        },
                        ender: false,
                        filter: {
                            container: "more-contents__filters",
                            filters: {
                                categories: "more-contents__filters-categories"
                            },
                        },
                        increment: 3,
                        limit: 3,
                        service: '/services/infinite/video',
                        showall: "more-contents__all",
                        toggler: false,
                        onItemsAdded: function (obj) {
                            window.ytMediaVideoGallery.run();
                        }
                    })
                    break;
                case "news":
                    window.news = new InfiniteScroll({
                        anim: {
                            delay: 500,
                            item: "off",
                            loading: "on",
                            stagger: 100,
                            toggler: "off"
                        },
                        ender: {
                            template: '<div class="{{class}} newscards__ender"><p>{{message}}</p></div>',
                            message: notizieEsauriteLabel,
                        },
                        filter: {
                            container: "more-contents__filters",
                            filters: {
                                select: "more-contents__filters-select"
                            },
                        },
                        limit: 6,
                        increment: 3,
                        service: 'services/infinite/new',
                        showall: "more-contents__all",
                        toggler: {
                            template: '<div class="{{class}} newscards__toggler"><p>{{label}}</p></div>',
                            label: leggiDiPiuLabel
                        }
                    })
                    break;
                case "press-reviews":
                    window.pressReview = new InfiniteScroll({
                        anim: {
                            delay: 500,
                            item: "off",
                            loading: "on",
                            stagger: 100,
                            toggler: "off"
                        },
                        ender: {
                            template: '<div class="{{class}} press-preview__ender"><p>{{message}}</p></div>',
                            message: articoliEsauriteLabel,
                        },
                        increment: 3,
                        service: '/services/infinite/press',
                        toggler: {
                            template: '<div class="{{class}} press-preview__toggler"><p>{{label}}</p></div>',
                            label: leggiDiPiuLabel
                        }
                    })
                    break;
                case "stories":
                    window.stories = new InfiniteScroll({
                        anim: {
                            delay: 500,
                            item: "off",
                            loading: "on",
                            stagger: 100,
                            toggler: "off"
                        },
                        ender: {
                            template: '<div class="{{class}} stories-previews__ender"><p>{{message}}</p></div>',
                            message: storieEsauriteLabel,
                        },
                        limit: 6,
                        increment: 3,
                        service: '/services/infinite/story',
                        toggler: {
                            template: '<div class="{{class}} stories-previews__toggler"><p>{{label}}</p></div>',
                            label: leggiDiPiuLabel
                        }
                    })
                    break;
            }
        });
    }

    // Youtube popups
    if (ytPopups.length > 0) {
        ytPopups.magnificPopup({
            type: 'iframe'
        });
    }

    // Goals
    if (goals.length > 0) {
        new Goals();
    }

    // Hiders
    if (hiders.length > 0) {

        //Traduzione label per il load more
        //---------------------------------------------------------------------------

        var leggiDiPiuLabel = 'Leggi di più';

        //Il locale dovrebbe settato come variabile globale
        try {
            if (typeof (locale) !== 'undefined') {
                if (locale == 'en') {
                    leggiDiPiuLabel = 'Load more';
                }
            }
        } catch (e) {
        }
        //---------------------------------------------------------------------------

        $.each(hiders, function (id, h) {
            var name = $(h).attr('name');
            switch (name) {
                case "news-text":
                    window.newsTextHider = new Hider({
                        closeClass: 'hider--close',
                        toggler: {
                            label: leggiDiPiuLabel,
                        }
                    });
                    break;
            }
        });
    }

    // Notfound
    if (notfound.length > 0) {
        var startSection = "sitenav";
        var wrapSections = ["visual", "content", "stories-banners", "portfolio-preview", "counters"];
        var notfound_tpl = $('.tpl_notfound').html();
        var switcher_tpl = '<div class="switcher"><div class="switcher__occulter"></div></div>';
        var switcherFX = {
            duration: 1000,
            easing: 'swing'
        };
        var occultedOpt = {
            left: 0,
            position: "absolute",
            top: 0,
            zIndex: 0
        };
        var visibleOpts = {
            position: "relative",
            zIndex: 1
        };

        // Occulter fitter function
        var fitOcculter = function (occulter, visualizer) {
            occulter.css('height', parseInt(visualizer.css('height')));
        }

        // Switcher container set
        $('section.' + startSection).after(switcher_tpl);
        var switcher = $('.switcher');

        // Occulter set
        var occulter = switcher.find('.switcher__occulter');
        occulter.css(occultedOpt);
        $.each(wrapSections, function (id, section) {
            $(occulter).append($('section.' + section));
        });

        // Visualizer set
        switcher.append(notfound_tpl);
        var visualizer = switcher.find('.notfound');
        visualizer.addClass('switcher__visualizer');
        visualizer.css(visibleOpts);

        // Toggler set
        visualizer.find('.notfound__toggler a').attr('href', 'javascript:void(0)').on('click', function (e) {
            e.preventDefault();
            var h = 0;

            // CSS switch set
            occulter.css('position', 'relative');
            visualizer.css({
                left: 0,
                position: 'absolute',
                top: 0
            });

            // Occuler contents total height
            $.each(occulter.children(), function (id, section) {
                h += parseInt($(section).css('height'));
            });

            // Visualizer animation
            visualizer.animate({
                opacity: 0
            }, $.extend({}, switcherFX, {
                complete: function () {
                    visualizer.remove();
                }
            }));

            // Occutler animation
            occulter.animate({
                height: h
            }, $.extend({}, switcherFX, {
                complete: function () {
                    $('.switcher__occulter').children().unwrap();
                    $('.switcher').children().unwrap();
                }
            }));

        });

        // Occulter fitting
        setTimeout(function () {
            fitOcculter(occulter, visualizer);
        }, 100);
        $('html, body').on('resizeend', function () {
            fitOcculter(occulter, visualizer);
        });

    }


    // Snackbar
    window.snackbar = new Snackbar();

    // Link copiers
    if (linkCopiers.length > 0) {

        //Traduzione label per il copy
        //---------------------------------------------------------------------------

        var linkCopiedLabel = 'Link copiato';

        //Il locale dovrebbe settato come variabile globale
        try {
            if (typeof (locale) !== 'undefined') {
                if (locale == 'en') {
                    linkCopiedLabel = 'Link copied';
                }
            }
        } catch (e) {
        }
        //---------------------------------------------------------------------------


        var linkCopyDelay = 2000;
        var clipboard = new ClipboardJS('.sharer__link a');

        console.log(clipboard);
        $.each(linkCopiers, function (id, l) {
            $(l).attr('data-clipboard-text', location.href);
        });
        clipboard.on('success', function (e) {
            var copied = $(e.trigger).clone().addClass('copied').css('display', 'none').text(linkCopiedLabel);
            $(e.trigger).parent().append(copied);
            copied = $(e.trigger).parent().find('.copied');
            copied.fadeIn();
            $(e.trigger).hide();
            setTimeout(function () {
                copied.on('transitionend oTransitionEnd mozTransitionEnd webkitTransitionEnd', function () {
                    setTimeout(function () {
                        copied.remove();
                        $(e.trigger).fadeIn();
                    }, linkCopyDelay);
                }).removeAttr('class');
            }, linkCopyDelay);
        });
    }

    // Fitmax
    if (maxFitters.length > 0) {
        new Maxfitter();
    }


    $(document).ready(function () {

        var menuItems = $('ul.sectionnav__nav-wrapper li a.sectionnav__anchor');

        // urbanStoriesCards
        var storiesWrapper = document.querySelector('.stories-previews__wrapper');
        var storiesFiltersWrapper = document.querySelector('.wrapper-filter-container-stories')
        var storyContainer;

        //FINALE
        // for(let i = 0; i < urbanStoriesCards.length; i++) {
        //
        //     storyContainer = document.createElement('div');
        //     var storyImage = document.createElement('img');
        //     var initialDataCategory = urbanStoriesCards[i].category;
        //     var dataCategory = initialDataCategory.join(" ");
        //
        //     storyImage.src = urbanStoriesCards[i].story_preview.url;
        //     storyContainer.appendChild(storyImage);
        //     storyContainer.setAttribute('data-category', dataCategory);
        //     storyContainer.classList.add('story-article-box');
        //
        //
        //     storiesWrapper.appendChild(storyContainer);
        // };

        //TEST
        var storiesCardsPage = function() {
            if(typeof urbanStoriesCards !== "undefined"){
                for (let i = 0; i < urbanStoriesCards.length; i++) {

                    storyContainer = document.createElement('div');
                    var storyLink = document.createElement('a');
                    var storyImage = document.createElement('img');
                    var storyParagraphTitle = document.createElement('p');
                    var storyDatasContainer = document.createElement('div');
                    var storyTitleWrapper = document.createElement('div');
                    var storyWrapperIcons = document.createElement('div');

                    var dataCategory = urbanStoriesCards[i].category;
                    var dataUrlPage = urbanStoriesCards[i].url;
                    var storyTitle = urbanStoriesCards[i].title;
                    var dataIcons = urbanStoriesCards[i].category_icons;

                    storyDatasContainer.classList.add('stories-preview-title-container');
                    storyTitleWrapper.classList.add('wrapper-story-title');
                    storyWrapperIcons.classList.add('container-story-categories-icons');

                    if (dataCategory !== null) {
                        urbanStoriesCards[i].category.map(function (data) {
                            storyContainer.setAttribute('data-' + data, data);
                        });
                    } else {
                        storyContainer.setAttribute('data-category', 'test');
                    }

                    for (var icon in dataIcons) {
                        console.log(dataIcons[icon]);

                        var wrapperIcons = document.createElement('div');
                        var image = document.createElement('img');
                        image.classList.add('categories-icons');
                        image.src = dataIcons[icon];

                        wrapperIcons.classList.add('wrapper-categories-icons');
                        wrapperIcons.appendChild(image);
                        storyWrapperIcons.appendChild(wrapperIcons);
                    }

                    storyImage.classList.add('preview-story-image');
                    try {
                        storyImage.src = urbanStoriesCards[i].story_preview.url;
                    } catch(e) {
                        storyImage.src = null;
                    }
                    storyLink.href = dataUrlPage;
                    storyParagraphTitle.innerHTML = storyTitle;
                    storyTitleWrapper.appendChild(storyParagraphTitle);
                    storyDatasContainer.appendChild(storyTitleWrapper);
                    storyDatasContainer.appendChild(storyWrapperIcons);
                    storyLink.appendChild(storyImage);
                    storyLink.appendChild(storyDatasContainer);
                    storyContainer.appendChild(storyLink);
                    storyContainer.classList.add('story-article-box');

                    storiesWrapper.appendChild(storyContainer);
                };
            }

        }

        var storiesCardsFilter = function() {
            if(typeof urbanStoriesCards !== "undefined") {
                for (let i = 0; i < urbanStoriesCards.length; i++) {

                    storyContainer = document.createElement('div');
                    var storyLink = document.createElement('a');
                    var storyImage = document.createElement('img');
                    var storyParagraphTitle = document.createElement('p');
                    var storyDatasContainer = document.createElement('div');
                    var storyTitleWrapper = document.createElement('div');
                    var storyWrapperIcons = document.createElement('div');

                    var dataCategory = urbanStoriesCards[i].category;
                    var dataUrlPage = urbanStoriesCards[i].url;
                    var storyTitle = urbanStoriesCards[i].title;
                    var dataIcons = urbanStoriesCards[i].category_icons;

                    storyDatasContainer.classList.add('stories-preview-title-container');
                    storyTitleWrapper.classList.add('wrapper-story-title');
                    storyWrapperIcons.classList.add('container-story-categories-icons');

                    if (dataCategory !== null) {
                        urbanStoriesCards[i].category.map(function (data) {
                            storyContainer.setAttribute('data-' + data, data);
                        });
                    } else {
                        storyContainer.setAttribute('data-category', 'test');
                    }

                    for (var icon in dataIcons) {
                        console.log(dataIcons[icon]);

                        var wrapperIcons = document.createElement('div');
                        var image = document.createElement('img');
                        image.classList.add('categories-icons');
                        image.src = dataIcons[icon];

                        wrapperIcons.classList.add('wrapper-categories-icons');
                        wrapperIcons.appendChild(image);
                        storyWrapperIcons.appendChild(wrapperIcons);
                    }

                    storyImage.classList.add('preview-story-image');
                    try {
                        storyImage.src = urbanStoriesCards[i].story_preview.url;
                    } catch(e) {
                        storyImage.src = null;
                    }
                    storyLink.href = dataUrlPage;
                    storyParagraphTitle.innerHTML = storyTitle;
                    storyTitleWrapper.appendChild(storyParagraphTitle);
                    storyDatasContainer.appendChild(storyTitleWrapper);
                    storyDatasContainer.appendChild(storyWrapperIcons);
                    storyLink.appendChild(storyImage);
                    storyLink.appendChild(storyDatasContainer);
                    storyContainer.appendChild(storyLink);
                    storyContainer.classList.add('story-article-box');

                    storiesFiltersWrapper.appendChild(storyContainer);
                };
            }
        }

        var filtersUrbanStory = function () {
            var storiesFilters = document.querySelectorAll('.stories-filter');
            var filterContainerTitle = document.querySelector('.filter-container-title');
            var storiesContainers = document.querySelectorAll('.story-article-box');

            [].slice.call(storiesFilters).forEach(function (filter) {
                var filterTitle = filter.dataset.attribute;
                var filterCategory = filterTitle.split(" ").join("-").toLowerCase();

                filter.addEventListener('click', function (e) {
                    e.preventDefault();

                    filterContainerTitle.innerHTML = filterTitle;

                    [].slice.call(storiesContainers).forEach(function (container) {
                        var currentDataset = container.dataset;

                        var counter = 0;
                        for (var d in currentDataset) {
                            if (currentDataset[d] === filterCategory) {
                                counter++
                            }
                        }

                        if (counter > 0) {
                            container.classList.add('story-article-box-visibility');
                        } else {
                            container.classList.remove('story-article-box-visibility');
                        }
                    });
                });
            });
        };

        var visibilityFiltersCards = function () {
            var storiesFilters = document.querySelectorAll('.stories-filter');
            var filterStoriesWrapper = document.querySelector('.urban-stories-filter-container');
            var storiesContainers = document.querySelectorAll('.wrapper-filter-container-stories .story-article-box');
            var resetFilter = document.querySelector('.reset-filters');

            [].slice.call(storiesFilters).forEach(function (filter) {
                var filterTitle = filter.dataset.attribute;
                var filterCategory = filterTitle.split(" ").join("-").toLowerCase();

                filter.addEventListener('click', function (e) {
                    e.preventDefault();

                    var activeFilters = [].slice.call(document.querySelectorAll('.urban-story-filter-active'));

                    filterStoriesWrapper.classList.add('story-filter-box-visibility');
                    resetFilter.classList.add('reset-filters-visibility');


                    [].slice.call(activeFilters).forEach(function(current) {
                        current.classList.remove('urban-story-filter-active');
                    });

                    filter.classList.add('urban-story-filter-active');

                    [].slice.call(storiesContainers).forEach(function (container) {
                        var currentDataset = container.dataset;

                        var counter = 0;
                        for (var d in currentDataset) {
                            if (currentDataset[d] === filterCategory) {
                                counter++
                            }
                        }

                        if (counter > 0) {
                            container.classList.add('story-filter-box-visibility');
                        } else {
                            container.classList.remove('story-filter-box-visibility');
                        }
                    });
                });
            });

            if(resetFilter !== null ){
                resetFilter.addEventListener('click', function() {
                    var activeFilter = document.querySelector('.urban-story-filter-active');
                    var hiddenStories = document.querySelectorAll('.story-article-box-visibility');

                    this.classList.remove('reset-filters-visibility');
                    activeFilter.classList.remove('urban-story-filter-active');
                    filterStoriesWrapper.classList.remove('story-filter-box-visibility');

                    [].slice.call(hiddenStories).forEach(function(current) {
                        current.classList.remove('story-article-box-visibility');
                    });
                });
            }

        };

        var filterQsUrl = function() {
            var getUrlParameter = function (name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            };



            console.log(getUrlParameter('category'));
            var storiesFilters = document.querySelectorAll('.stories-filter');
            [].slice.call(storiesFilters).forEach(function (liElement) {
                if(liElement.getAttribute('filter-qs-url') === getUrlParameter('category')){
                    console.log('Innesco click su ', liElement);
                    $(liElement).trigger('click');
                } else {
                    console.log('Non trovato ', liElement.getAttribute('data-tab'))
                }
            });
        }

        if(typeof urbanStoriesCards !== 'undefined'){
            storiesCardsPage();
            storiesCardsFilter();
            visibilityFiltersCards();
            filtersUrbanStory();
            filterQsUrl();
        }

        /*Accordion component*/
        class Accordion {
            constructor() {
                this.domAccordionComponent = document.querySelector('.section-accordion');
            }

            init() {
                if (!this.domAccordionComponent) return;

                this.activeAccordion();
            }

            activeAccordion() {
                let accordions = Array.from(this.domAccordionComponent.querySelectorAll('.accordion-title'));

                accordions.forEach((accordion) => {
                    const accordionContent = accordion.nextElementSibling;

                    accordion.addEventListener('click', () => {
                        if (accordionContent.style.maxHeight) {
                            this.closeAccordion(accordion);
                            this.scrollIntoViewWithOffset(accordion);
                        } else {
                            accordions.forEach((accordion) => {
                                this.closeAccordion(accordion)
                            });

                            this.openAccordion(accordion);

                            this.scrollIntoViewWithOffset(accordion);
                        }
                    });
                });
            }

            openAccordion(element) {
                const accordionContent = element.nextElementSibling;

                element.parentElement.classList.add('active');
                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
            }

            closeAccordion(element) {
                const accordionContent = element.nextElementSibling;

                element.parentElement.classList.remove('active');
                accordionContent.style.maxHeight = null;
            }

            scrollIntoViewWithOffset(accordion) {
                setTimeout(() =>  {
                    let elOffsetY = (accordion.getBoundingClientRect().top + window.pageYOffset) - 140;

                    window.scrollTo({
                        top: elOffsetY,
                        behavior: 'smooth'
                    });
                }, 500);
            }
        }

        const accordionComponent = new Accordion().init();

        class MenuProperties {
            constructor() {
                this.menu = document.querySelector('.wrapper-menu-properties');
            }

            init() {
                if (!this.menu) return;

                this.menuPosition();
                this.toggleActive();

                window.addEventListener('resize', () => {
                    this.menuPosition();
                });
            }

            menuPosition() {
                let visual = document.querySelector('.visual__wrapper');
                let visualBoundings = visual.getBoundingClientRect();

                this.menu.style.top = `${visualBoundings.bottom}px`;
            }

            toggleActive() {
                let headerMenu = this.menu.querySelector('.menu-header');

                headerMenu.addEventListener('click', () => {
                   this.menu.classList.toggle('active');
                });
            }
        }

        const menuProperties = new MenuProperties().init();
    });
});
